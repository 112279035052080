import { CloudTickIcon } from "@shared/icons";
import React from "react";

type DfsFeatureProps = {
  title: string;
  description: string;
};

export const DfsFeature: React.FC<DfsFeatureProps> = ({ title, description }) => {
  return (
    <div className="flex gap-x-2.5">
      <CloudTickIcon />
      <div>
        <h4 className="leading-none uppercase font-bold">{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};
