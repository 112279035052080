import clsx from "clsx";

type ActionButtonProps = {
  children: React.ReactNode;
  href: string;
  actionClass: string;
  className?: string;
};

export const ActionButton: React.FC<ActionButtonProps> = ({ href, children, actionClass, className }) => {
  return (
    <a href={href} className={clsx("inline-block w-min", actionClass)}>
      <button
        className={clsx("py-2 px-5 border border-solid rounded-full uppercase", className)}
        data-testid="log-in-button"
      >
        {children}
      </button>
    </a>
  );
};
