export const footerLinks = [
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    title: "Terms of Service",
    link: "/terms-of-service",
  },
];
