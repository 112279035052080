import loginImg from "@shared/assets/images/login.png";
import loginCroppedImg from "@shared/assets/images/login_cropped.png";
import Image from "next/image";

export const BackgroundImage = () => {
  return (
    <>
      <div className="-z-10 absolute top-0 left-0 right-0 lg:w-[100vw - 50%] h-full object-cover object-left bg-gradient-to-r from-black to-transparent opacity-40 lg:opacity-100" />

      <Image
        src={loginImg}
        alt="login"
        priority
        className="hidden lg:block absolute -z-20 top-0 left-0 right-0 bottom-0 w-full h-full object-right object-cover"
      />

      <Image
        src={loginCroppedImg}
        alt="login"
        priority
        className="lg:hidden -z-20 absolute opacity-50 top-0 left-0 right-0 bottom-0 w-full h-full object-right object-cover"
      />
    </>
  );
};
