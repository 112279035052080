export const dfsFeatures: Array<{ title: string; description: string }> = [
  {
    title: "AI Lineups",
    description: "Lock in Your Advantage",
  },
  {
    title: "AI Assistant",
    description: "Real-Time Research on Demand",
  },
  {
    title: "AI Projections",
    description: "Next-Level Player Predictions",
  },
];
