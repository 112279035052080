import { MailIcon, DiscordIcon, FacebookIcon } from "@shared/icons";
import { footerLinks } from "../../constants";

type FooterProps = {
  showEmailSignUpOption?: boolean;
};

export const Footer: React.FC<FooterProps> = ({ showEmailSignUpOption }) => {
  const currentYear = new Date().getFullYear();
  const socialLinks = [
    {
      icon: DiscordIcon,
      ariaLabel: "Discord",
      link: "https://discord.com/invite/KrScY8PuEF",
    },
    {
      icon: FacebookIcon,
      ariaLabel: "Facebook",
      link: "https://www.facebook.com/dfsProDaily/",
    },
    {
      icon: MailIcon,
      ariaLabel: "Newsletter",
      link: "https://confirmsubscription.com/h/t/5CC8E5D99F8D3A5F",
    },
  ];

  return (
    <footer className="w-full bg-black text-white">
      <div className="flex items-center justify-center pt-6">
        <p className="mr-4 text-gray-350 font-semibold">STAY CONNECTED</p>
        <div className="flex items-center gap-x-4">
          {socialLinks.map((social, index) => (
            <a key={index} href={social.link} aria-label={social.ariaLabel} target="_blank">
              <social.icon />
            </a>
          ))}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center gap-x-8 gap-y-4 py-6">
        <p className="font-light text-center">
          Copyright <span className="text-xs mt-3">©</span> {currentYear} Heavy, Inc. All rights reserved
        </p>

        {footerLinks.map((linkItem) => (
          <a key={linkItem.title} href={linkItem.link} className="cursor-pointer hover:underline">
            <p className="font-light">{linkItem.title}</p>
          </a>
        ))}

        {showEmailSignUpOption && (
          <a href="javascript:void(0);" className="cursor-pointer hover:underline">
            <button className="fp-sign-up text-base font-light">Sign up with e-mail</button>
          </a>
        )}
      </div>
    </footer>
  );
};
