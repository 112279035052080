import { DfsProIcon } from "@shared/icons";
import { ActionButton } from "../ActionButton/ActionButton";
import { useUserStore } from "@entities/user";
import { Button } from "@shared/ui";
import { useRouter } from "next/navigation";

export const Header = () => {
  const userSignedIn = useUserStore((state) => state.signedIn);
  const router = useRouter();
  return (
    <div className="flex justify-between mb-20 mt-8 px-4 max-w-[1400px] mx-auto w-full">
      <div className="flex items-center gap-x-4">
        <DfsProIcon className="w-12 lg:w-14 fill-white" />

        <p className="text-4xl non-selectable">
          <span className="font-extralight">dfs</span>
          <span className="font-semibold">Pro</span>
        </p>
      </div>
      {userSignedIn ? (
        <Button variant="outline-white" onClick={() => router.push("/app")} className="rounded-xl">
          Go to app
        </Button>
      ) : (
        <ActionButton
          href="javascript:void(0);"
          actionClass="fp-sign-in"
          className="hover:bg-white hover:text-red-600 transition-colors"
        >
          Log in
        </ActionButton>
      )}
    </div>
  );
};
