import { useEffect, useLayoutEffect } from "react";

import { DfsFeature } from "../DfsFeature/DfsFeature";
import { BackgroundImage } from "../BackgroundImage/BackgroundImage";
import { Header } from "../Header/Header";
import { ActionButton } from "../ActionButton/ActionButton";

import { dfsFeatures } from "../../constants";

type WelcomePageProps = {
  Footer: React.ReactNode;
};

export const Welcome: React.FC<WelcomePageProps> = ({ Footer }) => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "black";

    return () => {
      document.body.style.backgroundColor = "";
    };
  });

  useEffect(() => {
    const event = new Event("initFlipPay");
    document.dispatchEvent(event);
  }, []);

  return (
    <>
      <BackgroundImage />

      <div className="flex min-h-screen text-white flex-col justify-center">
        <Header />

        <div className="flex-1 px-4 max-w-[1400px] mx-auto w-full">
          <h1 className="text-[65px] leading-[65px]">Play to Win.</h1>
          <h3 className="my-10 uppercase text-[22px] text-[#58C3FF] leading-[28px] tracking-wider">
            Unleash the power of Ai with DFSPRO
          </h3>

          <div className="mb-10 mr-auto grid grid-cols-1 gap-x-8 gap-y-6 ">
            {dfsFeatures.map((feature) => (
              <DfsFeature key={feature.title} {...feature} />
            ))}
          </div>

          <h2 className="text-[30px] leading-[45px]">
            <span className="text-red-600 uppercase">Special offer:</span>
            <br />
            Sign Up for 50 FREE AI Lineups
          </h2>

          <ActionButton
            href="javascript:void(0);"
            actionClass="fp-sign-up mt-8 mb-20"
            className="bg-red-600 border-red-600 hover:bg-transparent hover:border-red-600 hover:text-red-600 transition-colors text-lg font-bold"
          >
            Sign up now
          </ActionButton>
        </div>

        <>{Footer}</>
      </div>
    </>
  );
};
